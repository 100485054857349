let sanziLabelArr = [
  "000",
  "001",
  "002",
  "003",
  "004",
  "005",
  "006",
  "007",
  "008",
  "009",
  "011",
  "012",
  "013",
  "014",
  "015",
  "016",
  "017",
  "018",
  "019",
  "022",
  "023",
  "024",
  "025",
  "026",
  "027",
  "028",
  "029",
  "033",
  "034",
  "035",
  "036",
  "037",
  "038",
  "039",
  "044",
  "045",
  "046",
  "047",
  "048",
  "049",
  "055",
  "056",
  "057",
  "058",
  "059",
  "066",
  "067",
  "068",
  "069",
  "077",
  "078",
  "079",
  "088",
  "089",
  "099",
  '111',
  '112',
  '113',
  '114',
  '115',
  '116',
  '117',
  '118',
  '119',
  '122',
  '123',
  '124',
  '125',
  '126',
  '127',
  '128',
  '129',
  '133',
  '134',
  '135',
  '136',
  '137',
  '138',
  '139',
  '144',
  '145',
  '146',
  '147',
  '148',
  '149',
  '155',
  '156',
  '157',
  '158',
  '159',
  '166',
  '167',
  '168',
  '169',
  '177',
  '178',
  '179',
  '188',
  '189',
  '199',
  '222',
  '223',
  '224',
  '225',
  '226',
  '227',
  '228',
  '229',
  '233',
  '234',
  '235',
  '236',
  '237',
  '238',
  '239',
  '244',
  '245',
  '246',
  '247',
  '248',
  '249',
  '255',
  '256',
  '257',
  '258',
  '259',
  '266',
  '267',
  '268',
  '269',
  '277',
  '278',
  '279',
  '288',
  '289',
  '299',
  '333',
  '334',
  '335',
  '336',
  '337',
  '338',
  '339',
  '344',
  '345',
  '346',
  '347',
  '348',
  '349',
  '355',
  '356',
  '357',
  '358',
  '359',
  '366',
  '367',
  '368',
  '369',
  '377',
  '378',
  '379',
  '388',
  '389',
  '399',
  '444',
  '445',
  '446',
  '447',
  '448',
  '449',
  '455',
  '456',
  '457',
  '458',
  '459',
  '466',
  '467',
  '468',
  '469',
  '477',
  '478',
  '479',
  '488',
  '489',
  '499',
  '555',
  '556',
  '557',
  '558',
  '559',
  '566',
  '567',
  '568',
  '569',
  '577',
  '578',
  '579',
  '588',
  '589',
  '599',
  '666',
  '667',
  '668',
  '669',
  '677',
  '678',
  '679',
  '688',
  '689',
  '699',
  '777',
  '778',
  '779',
  '788',
  '789',
  '799',
  '888',
  '889',
  '899',
  '999',
];
let siziLabelArr=[
  '0001',
  '0011',
  '0012',
  '0013',
  '0014',
  '0015',
  '0016',
  '0017',


  '0018',
  '0019',
  '0111',
  '0112',
  '0113',
  '0114',
  '0115',
  '0116',


  '0117',
  '0118',
  '0119',
  '0122',
  '0123',
  '0124',
  '0125',
  '0126',


  '0127',
  '0128',
  '0129',
  '0133',
  '0134',
  '0135',
  '0136',
  '0137',


  '0138',
  '0139',
  '0144',
  '0145',
  '0146',
  '0147',
  '0148',
  '0149',


  '0155',
  '0156',
  '0157',
  '0158',
  '0159',
  '0166',
  '0167',
  '0168',


  '0169',
  '0177',
  '0178',
  '0179',
  '0188',
  '0189',
  '0199',
  '1111',


  '1112',
  '1113',
  '1114',
  '1115',
  '1116',
  '1117',
  '1118',
  '1119',


  '1122',
  '1123',
  '1124',
  '1125',
  '1126',
  '1127',
  '1128',
  '1129',


  '1133',
  '1134',
  '1135',
  '1136',
  '1137',
  '1138',
  '1139',
  '1144',


  '1145',
  '1146',
  '1147',
  '1148',
  '1149',
  '1155',
  '1156',
  '1157',


  '1158',
  '1159',
  '1166',
  '1167',
  '1168',
  '1169',
  '1177',
  '1178',


  '1179',
  '1188',
  '1189',
  '1199',
  '1222',
  '1223',
  '1224',
  '1225',


  '1226',
  '1227',
  '1228',
  '1229',
  '1233',
  '1234',
  '1235',
  '1236',


  '1237',
  '1238',
  '1239',
  '1244',
  '1245',
  '1246',
  '1247',
  '1248',


  '1249',
  '1255',
  '1256',
  '1257',
  '1258',
  '1259',
  '1266',
  '1267',


  '1268',
  '1269',
  '1277',
  '1278',
  '1279',
  '1288',
  '1289',
  '1299',


  '1333',
  '1334',
  '1335',
  '1336',
  '1337',
  '1338',
  '1339',
  '1344',


  '1345',
  '1346',
  '1347',
  '1348',
  '1349',
  '1355',
  '1356',
  '1357',


  '1358',
  '1359',
  '1366',
  '1367',
  '1368',
  '1369',
  '1377',
  '1378',


  '1379',
  '1388',
  '1389',
  '1399',
  '1444',
  '1445',
  '1446',
  '1447',


  '1448',
  '1449',
  '1455',
  '1456',
  '1457',
  '1458',
  '1459',
  '1466',


  '1467',
  '1468',
  '1469',
  '1477',
  '1478',
  '1479',
  '1488',
  '1489',


  '1499',
  '1555',
  '1556',
  '1557',
  '1558',
  '1559',
  '1566',
  '1567',


  '1568',
  '1569',
  '1577',
  '1578',
  '1579',
  '1588',
  '1589',
  '1599',


  '1666',
  '1667',
  '1668',
  '1669',
  '1677',
  '1678',
  '1679',
  '1688',


  '1689',
  '1699',
  '1777',
  '1778',
  '1779',
  '1788',
  '1789',
  '1799',


  '1888',
  '1889',
  '1899',
  '1999',
  '0002',
  '0022',
  '0023',
  '0024',


  '0025',
  '0026',
  '0027',
  '0028',
  '0029',
  '0222',
  '0223',
  '0224',


  '0225',
  '0226',
  '0227',
  '0228',
  '0229',
  '0233',
  '0234',
  '0235',


  '0236',
  '0237',
  '0238',
  '0239',
  '0244',
  '0245',
  '0246',
  '0247',


  '0248',
  '0249',
  '0255',
  '0256',
  '0257',
  '0258',
  '0259',
  '0266',


  '0267',
  '0268',
  '0269',
  '0277',
  '0278',
  '0279',
  '0288',
  '0289',


  '0299',
  '2222',
  '2223',
  '2224',
  '2225',
  '2226',
  '2227',
  '2228',


  '2229',
  '2233',
  '2234',
  '2235',
  '2236',
  '2237',
  '2238',
  '2239',


  '2244',
  '2245',
  '2246',
  '2247',
  '2248',
  '2249',
  '2255',
  '2256',


  '2257',
  '2258',
  '2259',
  '2266',
  '2267',
  '2268',
  '2269',
  '2277',


  '2278',
  '2279',
  '2288',
  '2289',
  '2299',
  '2333',
  '2334',
  '2335',


  '2336',
  '2337',
  '2338',
  '2339',
  '2344',
  '2345',
  '2346',
  '2347',


  '2348',
  '2349',
  '2355',
  '2356',
  '2357',
  '2358',
  '2359',
  '2366',


  '2367',
  '2368',
  '2369',
  '2377',
  '2378',
  '2379',
  '2388',
  '2389',


  '2399',
  '2444',
  '2445',
  '2446',
  '2447',
  '2448',
  '2449',
  '2455',


  '2456',
  '2457',
  '2458',
  '2459',
  '2466',
  '2467',
  '2468',
  '2469',


  '2477',
  '2478',
  '2479',
  '2488',
  '2489',
  '2499',
  '2555',
  '2556',


  '2557',
  '2558',
  '2559',
  '2566',
  '2567',
  '2568',
  '2569',
  '2577',


  '2578',
  '2579',
  '2588',
  '2589',
  '2599',
  '2666',
  '2667',
  '2668',


  '2669',
  '2677',
  '2678',
  '2679',
  '2688',
  '2689',
  '2699',
  '2777',


  '2778',
  '2779',
  '2788',
  '2789',
  '2799',
  '2888',
  '2889',
  '2899',


  '2999',
  '0003',
  '0033',
  '0034',
  '0035',
  '0036',
  '0037',
  '0038',


  '0039',
  '0333',
  '0334',
  '0335',
  '0336',
  '0337',
  '0338',
  '0339',


  '0344',
  '0345',
  '0346',
  '0347',
  '0348',
  '0349',
  '0355',
  '0356',


  '0357',
  '0358',
  '0359',
  '0366',
  '0367',
  '0368',
  '0369',
  '0377',


  '0378',
  '0379',
  '0388',
  '0389',
  '0399',
  '3333',
  '3334',
  '3335',


  '3336',
  '3337',
  '3338',
  '3339',
  '3344',
  '3345',
  '3346',
  '3347',


  '3348',
  '3349',
  '3355',
  '3356',
  '3357',
  '3358',
  '3359',
  '3366',


  '3367',
  '3368',
  '3369',
  '3377',
  '3378',
  '3379',
  '3388',
  '3389',


  '3399',
  '3444',
  '3445',
  '3446',
  '3447',
  '3448',
  '3449',
  '3455',


  '3456',
  '3457',
  '3458',
  '3459',
  '3466',
  '3467',
  '3468',
  '3469',


  '3477',
  '3478',
  '3479',
  '3488',
  '3489',
  '3499',
  '3555',
  '3556',


  '3557',
  '3558',
  '3559',
  '3566',
  '3567',
  '3568',
  '3569',
  '3577',


  '3578',
  '3579',
  '3588',
  '3589',
  '3599',
  '3666',
  '3667',
  '3668',


  '3669',
  '3677',
  '3678',
  '3679',
  '3688',
  '3689',
  '3699',
  '3777',


  '3778',
  '3779',
  '3788',
  '3789',
  '3799',
  '3888',
  '3889',
  '3899',


  '3999',
  '0004',
  '0044',
  '0045',
  '0046',
  '0047',
  '0048',
  '0049',


  '0444',
  '0445',
  '0446',
  '0447',
  '0448',
  '0449',
  '0455',
  '0456',


  '0457',
  '0458',
  '0459',
  '0466',
  '0467',
  '0468',
  '0469',
  '0477',


  '0478',
  '0479',
  '0488',
  '0489',
  '0499',
  '4444',
  '4445',
  '4446',


  '4447',
  '4448',
  '4449',
  '4455',
  '4456',
  '4457',
  '4458',
  '4459',


  '4466',
  '4467',
  '4468',
  '4469',
  '4477',
  '4478',
  '4479',
  '4488',


  '4489',
  '4499',
  '4555',
  '4556',
  '4557',
  '4558',
  '4559',
  '4566',


  '4567',
  '4568',
  '4569',
  '4577',
  '4578',
  '4579',
  '4588',
  '4589',


  '4599',
  '4666',
  '4667',
  '4668',
  '4669',
  '4677',
  '4678',
  '4679',


  '4688',
  '4689',
  '4699',
  '4777',
  '4778',
  '4779',
  '4788',
  '4789',


  '4799',
  '4888',
  '4889',
  '4899',
  '4999',
  '0005',
  '0055',
  '0056',


  '0057',
  '0058',
  '0059',
  '0555',
  '0556',
  '0557',
  '0558',
  '0559',


  '0566',
  '0567',
  '0568',
  '0569',
  '0577',
  '0578',
  '0579',
  '0588',


  '0589',
  '0599',
  '5555',
  '5556',
  '5557',
  '5558',
  '5559',
  '5566',


  '5567',
  '5568',
  '5569',
  '5577',
  '5578',
  '5579',
  '5588',
  '5589',


  '5599',
  '5666',
  '5667',
  '5668',
  '5669',
  '5677',
  '5678',
  '5679',


  '5688',
  '5689',
  '5699',
  '5777',
  '5778',
  '5779',
  '5788',
  '5789',


  '5799',
  '5888',
  '5889',
  '5899',
  '5999',
  '0006',
  '0066',
  '0067',


  '0068',
  '0069',
  '0666',
  '0667',
  '0668',
  '0669',
  '0677',
  '0678',


  '0679',
  '0688',
  '0689',
  '0699',
  '6666',
  '6667',
  '6668',
  '6669',


  '6677',
  '6678',
  '6679',
  '6688',
  '6689',
  '6699',
  '6777',
  '6778',


  '6779',
  '6788',
  '6789',
  '6799',
  '6888',
  '6889',
  '6899',
  '6999',


  '0007',
  '0077',
  '0078',
  '0079',
  '0777',
  '0778',
  '0779',
  '0788',


  '0789',
  '0799',
  '7777',
  '7778',
  '7779',
  '7788',
  '7789',
  '7799',


  '7888',
  '7889',
  '7899',
  '7999',
  '0008',
  '0088',
  '0089',
  '0888',


  '0889',
  '0899',
  '8888',
  '8889',
  '8899',
  '8999',
  '0009',
  '0099',


  '0999',
  '9999',
  '0000',
]
siziLabelArr.sort((a,b)=>Number(a)-Number(b))

//  特肖 数据模板
let texiaoData = [
  {
    label: "鼠",
    money: "",
    Odds: "",
    selected: false,
    b: false,
    balls: [
      { color: "blue", num: "03" },
      { color: "blue", num: "15" },
      { color: "green", num: "27" },
      { color: "green", num: "39" },
    ],
  },
  {
    label: "牛",
    money: "",
    Odds: "",
    selected: false,
    b: false,
    balls: [
      { color: "red", num: "02" },
      { color: "blue", num: "14" },
      { color: "blue", num: "26" },
      { color: "green", num: "38" },
    ],
  },
  {
    label: "虎",
    money: "",
    Odds: "",
    selected: false,
    b: false,
    balls: [
      { color: "red", num: "01" },
      { color: "red", num: "13" },
      { color: "blue", num: "25" },
      { color: "blue", num: "37" },
      { color: "green", num: "49" },
    ],
  },
  {
    label: "兔",
    money: "",
    Odds: "",
    selected: false,
    b: false,
    balls: [
      { color: "red", num: "12" },
      { color: "red", num: "24" },
      { color: "blue", num: "36" },
      { color: "blue", num: "48" },
    ],
  },
  {
    label: "龙",
    money: "",
    Odds: "",
    selected: false,
    b: false,
    balls: [
      { color: "green", num: "11" },
      { color: "red", num: "23" },
      { color: "red", num: "35" },
      { color: "blue", num: "47" },
    ],
  },
  {
    label: "蛇",
    money: "",
    Odds: "",
    selected: false,
    b: false,
    balls: [
      { color: "blue", num: "10" },
      { color: "green", num: "22" },
      { color: "red", num: "34" },
      { color: "red", num: "46" },
    ],
  },
  {
    label: "马",
    money: "",
    Odds: "",
    selected: false,
    b: false,
    balls: [
      { color: "blue", num: "09" },
      { color: "green", num: "21" },
      { color: "green", num: "33" },
      { color: "red", num: "45" },
    ],
  },

  {
    label: "羊",
    money: "",
    Odds: "",
    selected: false,
    b: false,
    balls: [
      { color: "red", num: "08" },
      { color: "blue", num: "20" },
      { color: "green", num: "32" },
      { color: "green", num: "44" },
    ],
  },

  {
    label: "猴",
    money: "",
    Odds: "",
    selected: false,
    b: false,
    balls: [
      { color: "red", num: "07" },
      { color: "red", num: "19" },
      { color: "blue", num: "31" },
      { color: "green", num: "43" },
    ],
  },

  {
    label: "鸡",
    money: "",
    Odds: "",
    selected: false,
    b: false,
    balls: [
      { color: "green", num: "06" },
      { color: "red", num: "18" },
      { color: "red", num: "30" },
      { color: "blue", num: "42" },
    ],
  },

  {
    label: "狗",
    money: "",
    Odds: "",
    selected: false,
    b: false,
    balls: [
      { color: "green", num: "05" },
      { color: "green", num: "17" },
      { color: "red", num: "29" },
      { color: "blue", num: "41" },
    ],
  },

  {
    label: "猪",
    money: "",
    Odds: "",
    selected: false,
    b: false,
    balls: [
      { color: "blue", num: "04" },
      { color: "green", num: "16" },
      { color: "green", num: "28" },
      { color: "red", num: "40" },
    ],
  },
];
// 特头尾数据模板
let tetouweiData = [
  {
    label: "1头",
    rate: "1.2",
    cash: "",
    selected: false,
    b: false,
  },
  {
    label: "0尾",
    rate: "1.2",
    cash: "",
    selected: false,
    b: false,
  },
  {
    label: "5尾",
    rate: "1.2",
    cash: "",
    selected: false,
    b: false,
  },
  {
    label: "2头",
    rate: "1.2",
    cash: "",
    selected: false,
    b: false,
  },
  {
    label: "1尾",
    rate: "1.2",
    cash: "",
    selected: false,
    b: false,
  },
  {
    label: "6尾",
    rate: "1.2",
    cash: "",
    selected: false,
    b: false,
  },
  {
    label: "3头",
    rate: "1.2",
    cash: "",
    selected: false,
    b: false,
  },
  {
    label: "2尾",
    rate: "1.2",
    cash: "",
    selected: false,
    b: false,
  },
  {
    label: "7尾",
    rate: "1.2",
    cash: "",
    selected: false,
    b: false,
  },
  {
    label: "4头",
    rate: "1.2",
    cash: "",
    selected: false,
    b: false,
  },
  {
    label: "3尾",
    rate: "1.2",
    cash: "",
    selected: false,
    b: false,
  },
  {
    label: "8尾",
    rate: "1.2",
    cash: "",
    selected: false,
    b: false,
  },
  {
    label: "0头",
    rate: "1.2",
    cash: "",
    selected: false,
    b: false,
  },
  {
    label: "4尾",
    rate: "1.2",
    cash: "",
    selected: false,
    b: false,
  },
  {
    label: "9尾",
    rate: "1.2",
    cash: "",
    selected: false,
    b: false,
  },
];
// 正码1-6
let zhengma16Data = [
  {
    label: "单码",
    cash: "",
    b: false,
    rate: 1.2,
    selected: false,
    color: "",
  },
  {
    label: "双码",
    cash: "",
    rate: 12,
    b: false,
    selected: false,
    color: "",
  },
  {
    label: "大码",
    cash: "",
    rate: 12,
    b: false,
    selected: false,
    color: "",
  },
  {
    label: "小码",
    cash: "",
    rate: 12,
    b: false,
    selected: false,
    color: "",
  },
  {
    label: "合单",
    cash: "",
    rate: 12,
    b: false,
    selected: false,
    color: "",
  },
  {
    label: "合双",
    cash: "",
    rate: 12,
    b: false,
    selected: false,
    color: "",
  },
  {
    label: "合大",
    cash: "",
    rate: 12,
    b: false,
    selected: false,
    color: "",
  },
  {
    label: "合小",
    cash: "",
    rate: 12,
    b: false,
    selected: false,
    color: "",
  },
  {
    label: "尾大",
    cash: "",
    rate: 12,
    b: false,
    selected: false,
    color: "",
  },
  {
    label: "尾小",
    cash: "",
    rate: 12,
    b: false,
    selected: false,
    color: "",
  },
  {
    label: "红波",
    cash: "",
    rate: 12,
    b: false,
    selected: false,
    color: "red",
  },
  {
    label: "蓝波",
    cash: "",
    rate: 12,
    b: false,
    selected: false,
    color: "blue",
  },
  {
    label: "绿波",
    cash: "",
    rate: 12,
    b: false,
    selected: false,
    color: "green",
  },
];
let zixuanbuzhongData = [
  {
    color: "red",
    selected: false,
    label: "01",
  },
  {
    color: "red",
    selected: false,
    label: "02",
  },
  {
    color: "blue",
    selected: false,
    label: "03",
  },
  {
    color: "blue",
    selected: false,
    label: "04",
  },
  {
    color: "green",
    selected: false,
    label: "05",
  },
  {
    color: "green",
    selected: false,
    label: "06",
  },
  {
    color: "red",
    selected: false,
    label: "07",
  },
  {
    color: "red",
    selected: false,
    label: "08",
  },
  {
    color: "blue",
    selected: false,
    label: "09",
  },
  {
    color: "blue",
    selected: false,
    label: "10",
  },
  {
    color: "green",
    selected: false,
    label: "11",
  },
  {
    color: "red",
    selected: false,
    label: "12",
  },
  {
    color: "red",
    selected: false,
    label: "13",
  },
  {
    color: "blue",
    selected: false,
    label: "14",
  },
  {
    color: "blue",
    selected: false,
    label: "15",
  },
  {
    color: "green",
    selected: false,
    label: "16",
  },
  {
    color: "green",
    selected: false,
    label: "17",
  },
  {
    color: "red",
    selected: false,
    label: "18",
  },
  {
    color: "red",
    selected: false,
    label: "19",
  },
  {
    color: "blue",
    selected: false,
    label: "20",
  },
  {
    color: "green",
    selected: false,
    label: "21",
  },
  {
    color: "green",
    selected: false,
    label: "22",
  },
  {
    color: "red",
    selected: false,
    label: "23",
  },
  {
    color: "red",
    selected: false,
    label: "24",
  },
  {
    color: "blue",
    selected: false,
    label: "25",
  },
  {
    color: "blue",
    selected: false,
    label: "26",
  },
  {
    color: "green",
    selected: false,
    label: "27",
  },
  {
    color: "green",
    selected: false,
    label: "28",
  },
  {
    color: "red",
    selected: false,
    label: "29",
  },
  {
    color: "red",
    selected: false,
    label: "30",
  },
  {
    color: "blue",
    selected: false,
    label: "31",
  },
  {
    color: "green",
    selected: false,
    label: "32",
  },
  {
    color: "green",
    selected: false,
    label: "33",
  },
  {
    color: "red",
    selected: false,
    label: "34",
  },
  {
    color: "red",
    selected: false,
    label: "35",
  },
  {
    color: "blue",
    selected: false,
    label: "36",
  },
  {
    color: "blue",
    selected: false,
    label: "37",
  },
  {
    color: "green",
    selected: false,
    label: "38",
  },
  {
    color: "green",
    selected: false,
    label: "39",
  },
  {
    color: "red",
    selected: false,
    label: "40",
  },
  {
    color: "blue",
    selected: false,
    label: "41",
  },
  {
    color: "blue",
    selected: false,
    label: "42",
  },
  {
    color: "green",
    selected: false,
    label: "43",
  },
  {
    color: "green",
    selected: false,
    label: "44",
  },
  {
    color: "red",
    selected: false,
    label: "45",
  },
  {
    color: "red",
    selected: false,
    label: "46",
  },
  {
    color: "blue",
    selected: false,
    label: "47",
  },
  {
    color: "blue",
    selected: false,
    label: "48",
  },
  {
    color: "green",
    selected: false,
    label: "49",
  },
];
// 生肖 排行
let shengxiao = [
  {
    label: "鼠",
    checked: false,
    selected: false,
  },
  {
    label: "虎",
    checked: false,
    selected: false,
  },
  {
    label: "龙",
    checked: false,
    selected: false,
  },
  {
    label: "马",
    checked: false,
    selected: false,
  },
  {
    label: "猴",
    checked: false,
    selected: false,
  },
  {
    label: "狗",
    checked: false,
    selected: false,
  },
  {
    label: "牛",
    checked: false,
    selected: false,
  },
  {
    label: "兔",
    checked: false,
    selected: false,
  },
  {
    label: "蛇",
    checked: false,
    selected: false,
  },
  {
    label: "羊",
    checked: false,
    selected: false,
  },
  {
    label: "鸡",
    checked: false,
    selected: false,
  },
  {
    label: "猪",
    checked: false,
    selected: false,
  },
];
// 一肖尾数  尾数 数据
let weishuData = [
  {
    label: "1尾",
    cash: "",
    rate: 12.3,
    selected: false,
    b: false,
    balls: [
      { color: "red", num: "01" },
      { color: "green", num: "11" },
      { color: "green", num: "21" },
      { color: "blue", num: "31" },
      { color: "blue", num: "41" },
    ],
  },
  {
    label: "0尾",
    cash: "",
    rate: 12.3,
    selected: false,
    b: false,
    balls: [
      { color: "blue", num: "10" },
      { color: "blue", num: "20" },
      { color: "red", num: "30" },
      { color: "red", num: "40" },
    ],
  },
  {
    label: "2尾",
    cash: "",
    rate: 12.3,
    selected: false,
    b: false,
    balls: [
      { color: "red", num: "02" },
      { color: "red", num: "12" },
      { color: "green", num: "22" },
      { color: "green", num: "32" },
      { color: "blue", num: "42" },
    ],
  },
  {
    label: "6尾",
    cash: "",
    rate: 12.3,
    selected: false,
    b: false,
    balls: [
      { color: "green", num: "06" },
      { color: "green", num: "16" },
      { color: "blue", num: "26" },
      { color: "blue", num: "36" },
      { color: "red", num: "46" },
    ],
  },
  {
    label: "3尾",
    cash: "",
    rate: 12.3,
    selected: false,
    b: false,
    balls: [
      { color: "blue", num: "03" },
      { color: "red", num: "13" },
      { color: "red", num: "23" },
      { color: "green", num: "33" },
      { color: "green", num: "43" },
    ],
  },
  {
    label: "7尾",
    cash: "",
    rate: 12.3,
    selected: false,
    b: false,
    balls: [
      { color: "red", num: "07" },
      { color: "green", num: "17" },
      { color: "green", num: "27" },
      { color: "blue", num: "37" },
      { color: "blue", num: "47" },
    ],
  },
  {
    label: "4尾",
    cash: "",
    rate: 12.3,
    selected: false,
    b: false,
    balls: [
      { color: "blue", num: "04" },
      { color: "blue", num: "14" },
      { color: "red", num: "24" },
      { color: "red", num: "34" },
      { color: "green", num: "44" },
    ],
  },
  {
    label: "8尾",
    cash: "",
    rate: 12.3,
    selected: false,
    b: false,
    balls: [
      { color: "red", num: "08" },
      { color: "red", num: "18" },
      { color: "green", num: "28" },
      { color: "green", num: "38" },
      { color: "blue", num: "48" },
    ],
  },
  {
    label: "5尾",
    cash: "",
    rate: 12.3,
    selected: false,
    b: false,
    balls: [
      { color: "green", num: "05" },
      { color: "blue", num: "15" },
      { color: "blue", num: "25" },
      { color: "red", num: "35" },
      { color: "red", num: "45" },
    ],
  },
  {
    label: "9尾",
    cash: "",
    rate: 12.3,
    selected: false,
    b: false,
    balls: [
      { color: "blue", num: "09" },
      { color: "red", num: "19" },
      { color: "red", num: "29" },
      { color: "green", num: "39" },
      { color: "green", num: "49" },
    ],
  },
];
// 正肖
let zhengxiaoData = [
  {
    label: "鼠",
    selected: false,
    b: false,
    rate: 12,
    cash: "",
  },
  {
    label: "马",
    selected: false,
    b: false,
    rate: 12,
    cash: "",
  },
  {
    label: "牛",
    selected: false,
    b: false,
    rate: 12,
    cash: "",
  },
  {
    label: "羊",
    selected: false,
    b: false,
    rate: 12,
    cash: "",
  },
  {
    label: "虎",
    selected: false,
    b: false,
    rate: 12,
    cash: "",
  },
  {
    label: "猴",
    selected: false,
    b: false,
    rate: 12,
    cash: "",
  },
  {
    label: "兔",
    selected: false,
    b: false,
    rate: 12,
    cash: "",
  },
  {
    label: "鸡",
    selected: false,
    b: false,
    rate: 12,
    cash: "",
  },
  {
    label: "龙",
    selected: false,
    b: false,
    rate: 12,
    cash: "",
  },
  {
    label: "狗",
    selected: false,
    b: false,
    rate: 12,
    cash: "",
  },
  {
    label: "蛇",
    selected: false,
    b: false,
    rate: 12,
    cash: "",
  },
  {
    label: "猪",
    selected: false,
    b: false,
    rate: 12,
    cash: "",
  },
];
export {
  siziLabelArr,
  sanziLabelArr,
  weishuData,
  shengxiao,
  texiaoData,
  tetouweiData,
  zhengma16Data,
  zixuanbuzhongData,
  zhengxiaoData,
};
