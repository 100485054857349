<template>
  <div class="container">
    <div class="main">
      <yanzheng />
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
      </div>
      <top-timer></top-timer>
      <div class="player listmain listmain-lhc type-lhc bjamlh game-bjamlh game-lhc-zmlm">
        <div class="data ctop">
          <ul class="datatop">
            <li @click="LHCslelctLianMa(i,ele)" :class="{selected:currentIndex==i}" v-for="(ele,i) in rowsData[0].arr" :key="ele.label" style="width: 16.66%">
              <ol class="td_name">{{ele.label}}</ol>
              <ol v-if="i!=0&&i!=3" class="td_rate">{{ele.Odds}}</ol>
              <ol v-if="i==0" class="td_rate">中二{{ele.Odds}}/中三{{zhongsan.Odds}}</ol>
              <ol v-if="i==3" class="td_rate">中二{{ele.Odds}}/中特{{zhongte.Odds}}</ol>
            </li>

          </ul>
        </div>
        <div class="data lhc-ddb-data">
          <h3 class="lhc-tuo">
            <span :class="type2 == 'fushi' ? 'selected' : ''" @click="selectedType2('fushi')">复式</span>
            <span :class="type2 == 'dantuo' ? 'selected' : ''" @click="selectedType2('dantuo')">胆拖</span>
            <span :class="type2 == 'duozu' ? 'selected' : ''" @click="selectedType2('duozu')">多组</span>
            <span :class="type2 == 'jiaochapeng' ? 'selected' : ''" @click="selectedType2('jiaochapeng')">交叉碰</span>
          </h3>
          <ul class="custome_ul">
            <li :class="{selected:item.selected,disable:item.disable}" v-for="(item, index) in numArr" :key="index" @click="setCurrentSelected(item)">
              <ol class="td_name">
                <div :class="item.color + 'ball b' + (index + 1)">
                  {{ index + 1 }}
                </div>
              </ol>
              <!-- <ol style="width: 100px" class="td_chkbox">
                <input type="checkbox" value="item_64791" />
              </ol> -->
            </li>
            <li></li>
          </ul>
        </div>
        <!-- 拖胆 -->
        <div class="data ctuo" v-if="type2 == 'dantuo'">
          <h3>拖胆</h3>
          <ul class="custome_ul">
            <li v-for="(item, index) in dantuoData" :key="index" @click="setDanTuo(item)" :class="{disable:item.disable,selected:item.selected}">
              <ol class="td_name">
                <div :class="item.color + 'ball b' + (index + 1)">
                  {{ index + 1 }}
                </div>
              </ol>

            </li>
            <li></li>
          </ul>
        </div>
        <div v-if="type2 == 'fushi'" class="player listmain tuoduopeng type-lhc bjamlh game-bjamlh game-lhc-zmlm">
          <div class="data">
            <h3>注单号码 <span>共 <em class="red">{{fushiTotalNum}}</em> 种组合 </span></h3>

            <!-- <div class="dantuo-li">
             
            </div> -->
            <div class="dantuo-sc  red">
              <div>
                <span v-for="(item,index) in fsXuHeData" :key="index">[{{item.join(',')}}]</span>
              </div>
            </div>
          </div>
        </div>
        <!--胆拖 注单号码 -->
        <div v-if="type2 == 'dantuo'" class="player listmain tuoduopeng type-lhc bjamlh game-bjamlh game-lhc-zmlm">
          <div class="data">
            <h3>注单号码 <span>共 <em class="red">{{totalNum}}</em> 种组合 </span></h3>
            <div class="dantuo-li">
              <span>胆码:</span>
              <p v-for="lab in danma" :key="lab.label">[{{lab.label}}]</p>
            </div>
            <div class="dantuo-li">
              <span>拖码:</span>
              <p v-for="lab in tuoma" :key="lab.label">[{{lab.label}}]</p>
            </div>

            <div class="dantuo-sc  red">
              <div v-for="(item,index) in dantuoZhuDan" :key="index">
                <span v-for="spa in item" :key="JSON.stringify(spa)">[{{spa.join(',')}}]</span>

              </div>

            </div>
          </div>
        </div>
        <!-- 多组 注单号码 -->
        <div v-if="type2 == 'duozu'" class="player listmain tuoduopeng type-lhc bjamlh game-bjamlh game-lhc-zmlm">
          <div class="data">
            <h3>注单号码 <span>共 <em class="red">{{duozuTotalNum}}</em> 种组合 </span></h3>

            <div class="dantuo-sc  red">
              <div>
                <span v-for="(item,index) in duozuData" :key="index">[{{item.join(',')}}]</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 交叉碰 注单号码 -->
        <div v-if="type2=='jiaochapeng'" class="player listmain tuoduopeng type-lhc twlh game-twlh game-lhc-zmlm">
          <div class="data jiaochapen">
            <h3>注单号码 <span> 共 <em class="red">{{jcpTotalNum}}</em> 种组合 </span></h3>
            <div class="radio-list">
              <div @click="jcIndex=index" class="rli" :class="jcIndex==index?'selected':''" v-for="(jcrow,index) in jcRows" :key="index">
                <b></b>
                <div class="rballs">
                  <div v-for="item in jcrow" :class="item.color+'ball b'+item.label" :key="item.label"></div>
                </div>
              </div>

            </div>
            <!-- <div class="dantuo-li">
             
            </div> -->
            <div class="dantuo-sc  red">
              <div>
                <span v-for="(item,index) in jcpZuHeData" :key="index">[{{item.join(',')}}]</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <yushe />
    </div>

    <div class="other">
      <div class="zezhao" v-if="zezhao||weihu"></div>
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="rc-tab-long" v-if="changelongActive=='1'">
          <!-- <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div> -->
          <ul class="longlist" v-if="kaiActive=='1'">
            <li v-for="item in rclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="red">{{item.num}}期</b>
            </li>
          </ul>
          <ul class="longlist" v-if="kaiActive=='2'">
            <li v-for="item in lclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="blue">{{item.num}}期</b>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import yanzheng from "../yanzheng.vue";
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import { zixuanbuzhongData } from "../data";
import mixins from "../mixins/mainMixins";
/**
 * 求：组合C(m, n)，m为上标，n为下标。m选n的所有项
 * m {必传} 原始数据
 * n {必传} 当前项还需元素的个数
 * currentIndex 当前索引
 * choseArr 当前项的部分元素集合（不是完整项，是生成完整项的一个中间状态）
 * result 所有项的结果结合
 */
function cmn(m, n, currentIndex = 0, choseArr = [], result = []) {
  let mLen = m.length;
  // 可选数量小于项所需元素的个数，则递归终止
  if (currentIndex + n > mLen) {
    return;
  }
  for (let i = currentIndex; i < mLen; i++) {
    // n === 1的时候，说明choseArr在添加一个元素，就能生成一个新的完整项了。
    // debugger
    if (n === 1) {
      // 再增加一个元素就能生成一个完整项，再加入到结果集合中
      result.push([...choseArr, m[i]]);
      // 继续下一个元素生成一个新的完整项
      i + 1 < mLen && cmn(m, n, i + 1, choseArr, result);
      break;
    }
    // 执行到这，说明n > 2，choseArr还需要两个以上的元素，才能生成一个新的完整项。则递归，往choseArr添加元素
    cmn(m, n - 1, i + 1, [...choseArr, m[i]], result);
  }
  return result;
}
export default {
  name: "lhclianma",
  components: { topTimer, yushe, yanzheng },
  props: [""],
  mixins: [mixins],
  data() {
    return {
      groupnames:'lma',
      // 复试
      fushiTotalNum: 0,
      fsXuHeData: 0,
      //  交叉碰
      jcRows: [],
      jcIndex: 0,
      jcpTotalNum: 0,
      jcpZuHeData: [], //交叉碰组合数据
      // -------------------
      // 胆码
      danma: [],
      danmaOdds: [],
      // 拖码
      tuoma: [],
      tuomaOdds: [],
      dantuoZhuDan: [],
      dantuoOdds: [],
      totalNum: 0,
      // 胆拖
      dantuoData: JSON.parse(JSON.stringify(zixuanbuzhongData)),
      // 多组
      duozuData: [],
      duozuOdds: [],
      duozuItems: [],
      duozuTotalNum: 0,
      // -----------------
      labelArr: ["三中二", "三全中", "二全中", "二中特", "特串", "四全中"],
      zhongsan: {},
      zhongte: {},
      currentIndex: 0,
      bzArr: [3, 3, 2, 2, 2, 4],
      bzIndex: 3,
      title: "三中二",
      titleId: 0,
      titleNum: 3,
      numArr: JSON.parse(JSON.stringify(zixuanbuzhongData)),
      //   复试  胆拖 多组  交叉碰 active
      type2: "fushi",

      changelongActive: "1",
      kaiActive: "1",
      rowsData: [{ title: "连码", arr: [] }],
    };
  },
  watch: {
    jcRows: {
      deep: true,
      handler() {
        this.watchJCP();
      },
    },
    ISOPEN(val) {
      if (val) {
        this.zhongsan.Odds = this.zhongsan.recordOdds;
        this.zhongte.Odds = this.zhongte.recordOdds;
      } else {
        this.zhongsan.Odds = "--";
        this.zhongte.Odds = "--";
      }
    },
    // 多组 注单
    duozuData: {
      deep: true,
      handler(val) {
        this.duoduWatch();
      },
    },
    // 胆拖 注单
    dantuoZhuDan: {
      deep: true,
      handler(val) {
        // console.log("dantuozhudan", val);
        this.totalNum = 0;
        val.forEach((item) => {
          item.forEach((ele) => {
            this.totalNum++;
          });
        });
        if (val.length > 0) {
          let currentItem = this.rowsData[0].arr[this.currentIndex];
          let currentOdds = "";
          if (this.currentIndex == 0) {
            currentOdds = currentItem.Odds + "/" + this.zhongsan.Odds;
          } else if (this.currentIndex == 3) {
            currentOdds = currentItem.Odds + "/" + this.zhongte.Odds;
          } else {
            currentOdds = currentItem.Odds;
          }
          let zdArr = [];
          val.forEach((item, index) => {
            item.forEach((ele, i) => {
              let obj = {
                label: ele.join(","),
                num: ele.join(","),
                title: this.title,
                id: this.titleId,
                money: this.amount,
                Odds: currentOdds,
              };
              zdArr.push(obj);
            });
          });
          // console.log(zdArr, "下注ARR");
          this.$store.commit("setXZList", zdArr);
        } else {
          this.$store.commit("setXZList", []);
        }
      },
    },
    danma() {
      if (this.danma.length > 0) {
        if (this.tuoma.length >= this.titleNum - 1) {
          this.watchDanTuo();
        } else {
          this.$store.commit("setXZList", []);
        }
      } else {
        this.$store.commit("setXZList", []);
      }
    },
    tuoma() {
      if (this.danma.length > 0) {
        if (this.tuoma.length >= this.titleNum - 1) {
          this.watchDanTuo();
        } else {
          this.$store.commit("setXZList", []);
        }
      } else {
        this.$store.commit("setXZList", []);
      }
    },
    numArr: {
      deep: true,
      handler(val) {
        if (this.type2 == "fushi") {
          this.watchData();
        } else if (this.type2 == "duozu") {
          let selectedArr = this.numArr.filter((item) => item.selected);
          console.log(selectedArr, this.titleNum);
          if (selectedArr.length == this.titleNum) {
            let b = false;

            let arr1 = [];
            // let arr2 = [];
            selectedArr.forEach((item) => {
              arr1.push(item.label);
              // arr2.push(item.recordOdds);
            });
            if (this.duozuData.length > 0) {
              let newSelArr = JSON.parse(JSON.stringify(arr1));
              newSelArr.sort((a, b) => Number(a) - Number(b));
              this.duozuData.forEach((oddArr, index) => {
                let newOddArr = JSON.parse(JSON.stringify(oddArr));

                newOddArr.sort((a, b) => Number(a) - Number(b));

                // 判断是否是每个赔率都一样  计数
                let sameCount = 0;
                newOddArr.forEach((ele, i) => {
                  if (ele == newSelArr[i]) {
                    sameCount++;
                  }
                });
                // console.log(sameCount, newOddArr, newSelArr);
                if (sameCount == newOddArr.length) {
                  b = true;
                }
                // console.log(newOddArr, "yayayayaya");
              });
            }

            if (!b) {
              // this.duozuItems.push(JSON.parse(JSON.stringify(selectedArr)));
              this.duozuData.push(arr1);
              // this.duozuOdds.push(arr2);
            }

            this.numArr.forEach((item) => {
              item.selected = false;
            });
            // console.log(this.duozuData);
            this.$forceUpdate();
          }
        }
        // console.log(this.type2);
      },
    },
    oddData1: {
      immediate: true,
      deep: true,
      handler(resArr) {
        let arr = this.ddFilterData1(
          resArr,
          [788, 790, 791, 793, 794, 4033],
          this.labelArr
        );
        let arr1 = this.ddFilterData1(resArr, [789], ["中三"]);
        let arr2 = this.ddFilterData1(resArr, [792], ["中特"]);
        arr.forEach((item, index) => {
          item.bzNum = this.bzArr[index];
        });
        this.title = arr[0].label;
        this.titleId = arr[0].ResultID;
        this.titleNum = arr[0].bzNum;
        this.rowsData[0].arr = arr;
        this.zhongsan = arr1[0];
        this.zhongte = arr2[0];
        this.jcIndex = 0;
        this.jcRows = [];
        for (let index = 0; index < this.titleNum; index++) {
          this.jcRows.push([]);
        }
        if (this.ISOPEN) {
          this.zhongsan.Odds = this.zhongsan.recordOdds;
          this.zhongte.Odds = this.zhongte.recordOdds;
        }
        // console.log(this.jcRows, 111111111);
        // console.log(this.zhongsan, this.zhongte, "a啊啊啊啊啊");
      },
    },
    amount(val) {
      if (this.type2 == "fushi") {
        this.watchData();
      } else if (this.type2 == "dantuo") {
        this.watchDanTuo();
      } else if (this.type2 == "duozu") {
        this.duoduWatch();
      } else if (this.type2 == "jiaochapeng") {
        this.watchJCP();
      }
    },
  },
  computed: {
    ...mapState(["amount", "quickSelectedData", "quick_c", "quick_b"]),
  },
  created() {
    this.dantuoData.forEach((item) => {
      item.disable = false;
      item.selected = false;
    });
    this.numArr.forEach((item) => {
      item.disable = false;
      item.selected = false;
    });
    // console.log(this.rowsData, this.numArr, "numArrrr");
  },

  mounted() {},

  methods: {
    // 交叉碰
    watchJCP() {
      let b = this.jcRows.every((item) => item.length > 0);
      // console.log(b, this.jcRows);
      if (b) {
        this.jcpTotalNum = 0;
        this.jcpZuHeData = [];
        let currentItem = this.rowsData[0].arr[this.currentIndex];
        let currentOdds = "";
        if (this.currentIndex == 0) {
          currentOdds = currentItem.Odds + "/" + this.zhongsan.Odds;
        } else if (this.currentIndex == 3) {
          currentOdds = currentItem.Odds + "/" + this.zhongte.Odds;
        } else {
          currentOdds = currentItem.Odds;
        }
        let newArr = this.multiplyData(this.jcRows);
        let AllLabelArr = [];
        this.jcpTotalNum = 0;
        newArr.forEach((item) => {
          this.jcpTotalNum++;
          let arr = [];
          item.forEach((ele) => {
            arr.push(ele.label);
          });
          AllLabelArr.push(arr);
        });
        this.jcpZuHeData = AllLabelArr;
        let xiazhu = [];
        AllLabelArr.forEach((item) => {
          let obj = {
            label: item.join(","),
            num: item.join(","),
            title: this.title,
            id: this.titleId,
            money: this.amount,
            Odds: currentOdds,
          };
          xiazhu.push(obj);
        });
        this.$store.commit("setXZList", xiazhu);
        // console.log(AllLabelArr, 111111);
      } else {
        this.jcpTotalNum = 0;
        this.jcpZuHeData = [];
        this.$store.commit("setXZList", []);
      }
    },
    // 多组监听 Data
    duoduWatch() {
      this.duozuTotalNum = this.duozuData.length;
      // console.log(this.duozuData, this.duozuOdds);
      if (this.duozuData.length > 0) {
        let currentItem = this.rowsData[0].arr[this.currentIndex];
        let currentOdds = "";
        if (this.currentIndex == 0) {
          currentOdds = currentItem.Odds + "/" + this.zhongsan.Odds;
        } else if (this.currentIndex == 3) {
          currentOdds = currentItem.Odds + "/" + this.zhongte.Odds;
        } else {
          currentOdds = currentItem.Odds;
        }
        let zdArr = [];
        this.duozuData.forEach((item, index) => {
          let obj = {
            label: item.join(","),
            num: item.join(","),
            title: this.title,
            id: this.titleId,
            money: this.amount,
            Odds: currentOdds,
          };
          zdArr.push(obj);
        });
        this.$store.commit("setXZList", zdArr);
      } else {
        this.$store.commit("setXZList", []);
      }
    },
    // // 最大值 4
    // Math.max.apply(Math,this.list.map(item => { return item.id }))

    // 复试  监听数据
    getMin(arr, key) {
      // 最小值 1
      return Math.min.apply(
        Math,
        arr.map((item) => {
          return item;
        })
      );
    },
    // 监听胆拖
    watchDanTuo() {
      this.dantuoZhuDan = [];
      this.dantuoOdds = [];
      this.danma.forEach((dan, index) => {
        // 先把拖码 label和odds取出来
        let arr1 = [],
          arr2 = [];
        this.tuoma.forEach((tuo) => {
          arr1.push(tuo.label);
          // arr2.push(tuo.Odds);
        });
        // 把 label和odds 排列组合一下
        let arr3 = cmn(arr1, this.titleNum - 1);
        // let arr4 = cmn(arr2, this.titleNum - 1);
        // console.log(arr3, "arr333");
        //  把胆码 加到 排列组合里面 label 和odds
        arr3.forEach((item) => {
          item.unshift(dan.label);
        });
        // arr4.forEach((item) => {
        //   item.unshift(dan.Odds);
        // });
        this.dantuoZhuDan.push(arr3);
        // this.dantuoOdds.push(arr4);
      });
      // console.log(this.dantuoZhuDan);
    },
    //   点击当前li
    setCurrentSelected(ele) {
      if (ele.disable) {
        return;
      }

      if (!ele.selected) {
        if (this.selectionArr.length >= 10) {
          this.$message.warning("已超出最大项目数");
          return;
        }
      }
      if (ele.selected) {
        ele.selected = false;
        ele.money = "";
      } else {
        ele.selected = !ele.selected;
        ele.money = ele.selected ? this.amount : "";
      }
      if (this.type2 == "fushi") {
        this.selectionArr = [];
        this.numArr.forEach((item) => {
          if (item.selected) {
            this.selectionArr.push(item);
          }
        });
      }
      if (this.type2 == "dantuo") {
        let dantuoItem = this.dantuoData.find(
          (item) => item.label == ele.label
        );
        if (ele.selected) {
          dantuoItem.disable = true;
          this.danma.push(JSON.parse(JSON.stringify(ele)));
          // this.danmaOdds.push(ele.Odds);
        } else {
          dantuoItem.disable = false;
          let index = this.danma.findIndex((oo) => oo.label == ele.label);
          this.danma.splice(index, 1);
          // this.danmaOdds.splice(index,1);
        }
      } else if (this.type2 == "jiaochapeng") {
        // console.log(ele);
        this.jcRows[this.jcIndex].push(ele);
        ele.disable = true;
        ele.selected = false;
        // console.log(this.jcRows);
      }

      this.$forceUpdate();
    },
    // 点击当前胆拖
    setDanTuo(item) {
      if (item.disable) {
        return;
      }
      let liItem = this.numArr.find((element) => element.label == item.label);
      item.selected = !item.selected;
      item.checked = !item.checked;
      if (item.selected) {
        liItem.disable = true;
        this.tuoma.push(JSON.parse(JSON.stringify(item)));
      } else {
        liItem.disable = false;
        let index = this.tuoma.findIndex((oo) => oo.label == item.label);
        this.tuoma.splice(index, 1);
      }
    },
    watchData() {
      let arr = this.numArr.filter((item) => item.selected);
      this.fushiTotalNum = 0;
      this.fsXuHeData = [];
      let selArr = [];
      let currentItem = this.rowsData[0].arr[this.currentIndex];
      let currentOdds = "";
      if (this.currentIndex == 0) {
        currentOdds = currentItem.Odds + "/" + this.zhongsan.Odds;
      } else if (this.currentIndex == 3) {
        currentOdds = currentItem.Odds + "/" + this.zhongte.Odds;
      } else {
        currentOdds = currentItem.Odds;
      }
      // let OddsArr = [];
      let len = arr.length;
      if (len < this.titleNum) {
        this.$store.commit("setXZList", []);
      } else {
        arr.forEach((item) => {
          selArr.push(item.label);
          // OddsArr.push(item.Odds);
        });
        // console.log(selArr, "22222222222");
        // console.log(len, this.titleNum, 11111111);
        if (len == this.titleNum) {
          this.fushiTotalNum = 1;
          this.fsXuHeData = [selArr];
          let obj = {
            label: selArr.join(","),
            num: selArr.join(","),
            title: this.title,
            id: this.titleId,
            money: this.amount,
            Odds: currentOdds,
          };
          this.$store.commit("setXZList", [obj]);
        } else {
          // 选中的数据超过 title 的值
          let xiazhu = [];
          let cArr = cmn(selArr, this.titleNum);

          cArr.forEach((ele, index) => {
            this.fushiTotalNum++;
            this.fsXuHeData.push(ele);
            let obj = {
              label: ele.join(","),
              num: ele.join(","),
              title: this.title,
              id: this.titleId,
              money: this.amount,
              Odds: currentOdds,
            };
            xiazhu.push(obj);
          });
          this.$store.commit("setXZList", xiazhu);
        }
      }
    },
    //   type2
    selectedType2(str) {
      // this.selectedArr = [];
      this.selectionArr = [];
      this.jcpZuHeData = [];
      this.fushiTotalNum = 0;
      this.fsXuHeData = [];
      this.duozuItems = [];
      this.duozuData = [];
      this.duozuOdds = [];
      this.danma = [];
      this.tuoma = [];
      this.dantuoZhuDan = [];
      this.dantuoOdds = [];
      this.type2 = str;
      this.jcIndex = 0;
      this.jcRows = [];
      for (let index = 0; index < this.titleNum; index++) {
        this.jcRows.push([]);
      }
      this.numArr.forEach((ele) => {
        ele.selected = false;
        ele.disable = false;
      });
      this.dantuoData.forEach((element) => {
        element.selected = false;
        element.disable = false;
        // element.checked = false;
      });
    },
    multiplyData(newArrData) {
      let len = newArrData.length;
      // 当数组长度大于等于2时
      if (len >= 2) {
        // 第一个数组的长度
        let len1 = newArrData[0].length;
        // 第二个数组的长度
        let len2 = newArrData[1].length;
        //  申明一个新数组,做数据暂存
        let items = new Array();
        // 申明新数组的索引
        let index = 0;
        // 2层嵌套循环,将组合放到新数组中
        for (var i = 0; i < len1; i++) {
          for (var j = 0; j < len2; j++) {
            if (items.length < index + 1) {
              items.push([]);
            }
            if (newArrData[0][i] instanceof Array) {
              items[index] = items[index].concat(
                newArrData[0][i].concat(newArrData[1][j])
              );
            } else {
              items[index] = items[index].concat([
                newArrData[0][i],
                newArrData[1][j],
              ]);
            }
            index++;
          }
        }
        // 将新组合的数组并到原数组中
        let newArr = new Array(len - 1);
        for (let i = 2; i < newArrData.length; i++) {
          newArr[i - 1] = newArrData[i];
        }
        newArr[0] = items;
        // 执行回调
        return this.multiplyData(newArr);
      } else {
        // console.log(newArrData[0]);
        return newArrData[0];
      }
    },

    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
  },
};
</script>
<style scoped>
.listmain .jiaochapen .radio-list .rli .rballs > div {
  display: inline-block;
  margin-right: 15px;
  cursor: pointer;
}
.custome_ul li {
  min-height: 34px !important;
}
.custome_ul {
  height: 170px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.td_name {
  width: 100% !important;
}
/* 交叉碰 begin */
.listmain .jiaochapen .radio-list .rli.selected b:before {
  position: absolute;
  content: "";
  left: 4px;
  top: 4px;
  width: 10px;
  height: 10px;
  background: #999;
  border-radius: 100px;
}
.listmain .jiaochapen .radio-list .rli b {
  position: absolute;
  top: 10.5px;
  left: 15px;
  border: 1px solid #ccc;
  border-radius: 100px;
  height: 18px;
  width: 18px;
  cursor: pointer;
}
.listmain .jiaochapen .radio-list .rli {
  position: relative;
  min-height: 25px;
  padding: 8px 10px 5px 50px;
  border-bottom: 1px solid #eee;
}

.listmain .dantuo-sc div span {
  display: inline-block;
  margin-right: 10px;
}
.listmain .dantuo-sc div {
  padding-bottom: 5px;
  line-height: 22px;
}
.listmain .dantuo-sc {
  padding: 0 10px;
  max-height: 150px;
  overflow-y: scroll;
}
.scroll {
  overflow-x: hidden;
  overflow-y: auto;
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;
}

/* -----dantuo-li */

.skin-brown .listmain > .data > h3 {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #fff),
    color-stop(50%, #fff),
    color-stop(51%, #fdeee1),
    to(#fdeee1)
  );
  background: linear-gradient(180deg, #fff, #fff 50%, #fdeee1 51%, #fdeee1);
  color: #8c420b;
}
.skin-red .listmain > .data > h3,
.skin-red .listmain > .data > ul > li {
  border-top: 1px solid #e6b3be;
  border-left: 1px solid #e6b3be;
}
.skin-red .listmain > .data > h3 {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #fff),
    color-stop(50%, #fff),
    color-stop(51%, #ffefee),
    to(#ffefee)
  );
  background: linear-gradient(180deg, #fff, #fff 50%, #ffefee 51%, #ffefee);
  color: #762d08;
}

.skin-blue .listmain > .data > h3 {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    color-stop(50%, #fff),
    color-stop(51%, #fef4ea),
    to(#fef4ea)
  );
  background: linear-gradient(180deg, #fff 0, #fff 50%, #fef4ea 51%, #fef4ea);
  color: #4f4d4d;
}

.listmain.game-lhc-slx > .data > ul > li .td_name,
.listmain.game-lhc-zmlm > .data > ul > li .td_name {
  border-right: none;
}

.listmain > .data > ul > li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 10%;
  min-height: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 auto;
  cursor: pointer;
}

.listmain .ctop .datatop li {
  display: inline-block;
}
.listmain div.ctop .datatop li ol {
  width: auto !important;
  max-width: none !important;
  display: block;
  border-right: none !important;
}
</style>
